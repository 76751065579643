import vueFormGenerator from 'vue-form-generator';

export const model = {
	OplseasonId: 0,
	OplseasonNameSk: null,
	OplseasonNameCz: null,
	OplseasonNamePl: null,
	OplseasonNameDe: null,
	OplseasonNameEn: null,
	OplseasonNameHu: null,
	DateFrom: null,
	DateTo: null,
	IsBasic: true,
	Order: 0,
};

export const fields = [
			{
				fields: [
					{
						type: 'input',
						inputType: 'text',
						model: 'OplseasonNameSk',
						required: true,
						validator: vueFormGenerator.validators.required,
						i18n: {
							label: 'namesk2556',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OplseasonNameCz',
						required: true,
						validator: vueFormGenerator.validators.required,
						i18n: {
							label: 'namecz2558',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OplseasonNamePl',
						required: true,
						validator: vueFormGenerator.validators.required,
						i18n: {
							label: 'namepl2561',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OplseasonNameEn',
						required: true,
						validator: vueFormGenerator.validators.required,
						i18n: {
							label: 'nameen2564',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OplseasonNameDe',
						required: true,
						validator: vueFormGenerator.validators.required,
						i18n: {
							label: 'namede2562',
						},
					},
					{
						type: 'input',
						inputType: 'text',
						model: 'OplseasonNameHu',
						required: true,
						validator: vueFormGenerator.validators.required,
						i18n: {
							label: 'namehu2566',
						},
					},
					{
						type: 'single-datepicker',
						model: 'DateFrom',
						showtime: false,
						autoselect: true,
						language: 'sk',
						validator: vueFormGenerator.validators.date,
						i18n: {
							label: 'periodfrom',
						},
					},
					{
						type: 'single-datepicker',
						model: 'DateTo',
						showtime: false,
						autoselect: true,
						language: 'sk',
						validator: vueFormGenerator.validators.date,
						i18n: {
							label: 'periodto',
						},
					},
					{
						type: 'switch',
						model: 'IsBasic',
						i18n: {
							label: 'predefinedlinei',
							textOn: 'yes2220',
							textOff: 'no2221',
						},
					},
					{
						type: 'input',
						inputType: 'number',
						model: 'Order',
						required: true,
						validator: vueFormGenerator.validators.integer,
						styleClasses: 'quarter-width',
						i18n: {
							label: 'order2924',
						},
					},
				],
			},
		];
